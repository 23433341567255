<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-card-text class="text-center">
                    <v-avatar size="60" class="mb-4">
                        <img src="@/assets/images/svg/search.svg" alt="" />
                    </v-avatar>
                    <h1>Passive Vision</h1>

                    <h6 class="text--disabled font-weight-medium mb-10">
                        Sign in to your account
                    </h6>
                    <v-form>
                        <v-text-field
                            label="email"
                            v-model="email"
                            :rules="emailRules"
                            required
                        />

                        <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="password"
                            :counter="10"
                            :rules="passwordRules"
                            v-model="password"
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-checkbox
                            v-model="checkbox1"
                            label="Remember this computer"
                        ></v-checkbox>
                        <v-btn
                            class="mb-4"
                            @click="formSubmit"
                            block
                            color="primary"
                            dark
                        >
                            <v-icon left>mdi-login</v-icon>
                            Sign In</v-btn
                        >
                        <v-btn
                            class="mb-4"
                            @click="googleSignIn"
                            block
                            color="#DB4437"
                            dark
                        >
                            <v-icon left>
                                mdi-google
                            </v-icon>

                            Sign In
                        </v-btn>
                        <div class="d-flex justify-around flex-wrap">
                            <v-btn text small color="primary" class="mb-2"
                                >Forgot Password</v-btn
                            >
                            <v-btn
                                text
                                small
                                color="primary"
                                to="/app/sessions/sign-up-2"
                                >Create New Account</v-btn
                            >
                        </div>
                    </v-form>
                </v-card-text>

                <v-snackbar v-model="invalidPassword" top color="danger">
                    Invalid email address or password

                    <template v-slot:action="{attrs}">
                        <v-btn
                            color=""
                            text
                            v-bind="attrs"
                            @click="invalidPassword = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-snackbar>
            </base-card>
        </div>
    </div>
</template>
<script>

import {mapGetters, mapActions} from 'vuex'
import { firebaseAuth } from '../../../firebase/config'
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    GoogleAuthProvider
} from 'firebase/auth'
import { getUser, createUser } from '../../../data/userData';
import { setSessionObject } from '../../../session/sessionData';

export default {
    name: 'login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Login'
    },
    data() {
        return {
            show: false,
            password: '',
            checkbox1: true,
            checkbox2: false,
            email: '',
            invalidPassword: false,
            loading: false,
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordRules: [
                (v) => !!v || 'Password is required',
                (v) =>
                    (v && v.length >= 10) ||
                    'Password must be greater than 10 characters'
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    methods: {
        ...mapActions(['login']),
        login: function() {
          this.loading = true;
          signInWithEmailAndPassword(firebaseAuth, this.email, this.password)
            .then((result) => {
                this.loading = false;

                getUser(result.user.uid)
                    .then((user)=>{
                        if (user){
                            setSessionObject('user', user);
                        }
                        else
                        {
                            createUser(result.user.uid, '', '', this.email)
                                .then ((user)=>{
                                    setSessionObject('user', user);
                                });
                        }

                        this.$router.push('/');
                    });

                //todo add .err or something to deal with user not found and add a new one with uid

              },
              err => {
                this.loading = false;
                this.invalidPassword=true;
                console.log(err.message)
              }
            )
        },
        formSubmit() {
            this.login({email: this.email, password: this.password})
        },
        googleSignIn() {
            this.loading = true;
            const provider = new GoogleAuthProvider(firebaseAuth)

            signInWithPopup(firebaseAuth, provider)
                .then((result) => {
                    this.loading = false;
                    
                    console.log(result);

                    createUser(result.user.uid, '', '', result.user.email)
                        .then((user)=>{
                            setSessionObject('user', user); 
                            this.loading = false;
                            this.$router.push('/');
                        });
                    // getUser(result.user.uid)
                    // .then((user)=>{
                    //     console.log(user);
                    //     setSessionObject('user', user);
                    //     this.$router.push('/');
                    // });

                })
                .catch((err) => {
                    this.loading = false;
                    this.invalidPassword=true;
                    console.log(err.message)
                });

            }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.uid && val.uid.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                console.log('logged in successfully ')
                this.loading = true
                setTimeout(() => {
                    this.$router.push('/')
                }, 500)
            }
        },
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
